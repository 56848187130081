
const InfoUtils = {};

InfoUtils.parse = (info) => {
  const parsed = {
    policy: InfoUtils.parsePolicy(info),
    moderator: InfoUtils.parseModerator(info)
  };

  if (info.sipUri) {
    parsed.sipUri = info.sipUri;
  }

  return parsed;
};
InfoUtils.parsePolicy = (info) => {
  const policy = {};

  // Parses the policy to show valid uses
  if (info && info.displayHints && info.displayHints.joined && info.displayHints.joined.length > 0) {
    // TODO: find a better way
    info.displayHints.joined.forEach((key) => {
      policy[key] = true;
    });
  }

  return policy;
};

InfoUtils.parseModerator = (info) => {
  const moderator = {};

  if (info && info.displayHints && info.displayHints.moderator && info.displayHints.moderator.length > 0) {
    info.displayHints.moderator.forEach((key) => {
      moderator[key] = true;
    });
  }

  return moderator;
};

InfoUtils.isLocked = (policy) => policy.LOCK_STATUS_LOCKED || false;

InfoUtils.isUnlocked = (policy) => policy.LOCK_STATUS_UNLOCKED || false;

InfoUtils.getInfos = (oldInfo, newInfo) => {
  let previous = null;

  if (oldInfo) {
    previous = oldInfo;
  }
  const current = newInfo && InfoUtils.parse(newInfo);
  const updates = {};

  if (current) {
    current.isLocked = InfoUtils.isLocked(current.policy);
    current.isUnlocked = InfoUtils.isUnlocked(current.policy);

    if (previous && previous.isUnlocked && current.isLocked || !previous && current.isLocked) {
      updates.isLocked = current.isLocked;
    }
    if (previous && previous.isLocked && current.isUnlocked || !previous && current.isUnlocked) {
      updates.isUnlocked = current.isUnlocked;
    }
  }

  return {
    previous,
    current,
    updates: {
      isLocked: InfoUtils.isLocked(current),
      isUnlocked: InfoUtils.isUnlocked(current)
    }
  };
};

export default InfoUtils;
