import StateMachine from 'javascript-state-machine';
import StateMachineHistory from 'javascript-state-machine/lib/history';

import LoggerProxy from '../common/logs/logger-proxy';
import Media from '../media';
import MeetingUtil from '../meeting/util';
import {MEETING_VIDEO_STATE_MACHINE} from '../constants';

const handleTransition = (video) => {
  if (video.mute && video.self) {
    return MEETING_VIDEO_STATE_MACHINE.STATES.MUTE_SELF;
  }
  if (!video.mute && video.self) {
    return MEETING_VIDEO_STATE_MACHINE.STATES.UNMUTE_SELF;
  }

  return null;
};

const doToggle = (transition, video, meeting) => {
  Media.setLocalTrack(!video.mute, meeting.mediaProperties.videoTrack);
  const meetingAudio = meeting.audio;
  const audioMuted = meetingAudio ? meetingAudio.muted : true;

  return MeetingUtil.remoteUpdateAudioVideo(audioMuted, video.mute, meeting)
    .then((locus) => {
      LoggerProxy.logger.log(
        `Meeting:video#doToggle --> VideoStateMachine->onAfterToggle#${transition.event} fired! State changed from '${transition.from}' to '${
          transition.to
        }' with transition '${transition.transition}''.`
      );

      return locus;
    })
    .catch((remoteUpdateError) => {
      LoggerProxy.logger.log(
        `Meeting:video#doToggle --> VideoStateMachine->onBeforeToggle#${transition.event} fired! State failed to change with transition '${
          transition.transition
        }''. After local Video toggle failed, resetting remote also failed, meeting video in bad state with error: ${remoteUpdateError}.`
      );

      return Promise.reject(remoteUpdateError);
    });
};

const VideoStateMachine = {
  /**
   *
   * @param {Object} mediaDirection object containing media direction
   * @param {Boolean} mediaDirection.sendVideo Whether or not to send video in the meeting
   * @param {Meeting} meeting an instance of a Meeting
   * @returns {Statemachine} returns a state machine instance
   */
  create(mediaDirection, meeting) {
    if (!mediaDirection.sendVideo) {
      return undefined;
    }

    return new StateMachine({
      transitions: [
        {
          name: MEETING_VIDEO_STATE_MACHINE.TRANSITIONS.TOGGLE,
          from: '*',
          /**
           *
           * @param {Object} video current video data for the transition {mute, self}
           * @returns {String} a new state value for the transition
           */
          to(video) {
            return handleTransition(video) || this.state;
          }
        }
      ],
      data: {
        muted: false,
        self: true
      },
      methods: {
        /**
         * Convenience function to tell whether we are muted or not
         * @returns {Boolen} boolean that indicates whether the video is currently muted
         */
        isMuted() {
          return this.muted;
        },
        /**
         * Convenience function to tell who last muted/unmuted the video
         * @returns {Boolen} boolean that indicates whether the video was muted by the end user or server
         */
        isSelf() {
          return this.self;
        },

        /**
         *
         * @param {Object} video current video options to set on the state machine
         * @param {Boolean} video.mute indicates if video is muted or not for a meeting
         * @param {Boolean} video.self indicates whether the end user or server muted the video
         * @returns {null}
         */
        setData(video) {
          this.muted = video.mute;
          this.self = video.self;
        },

        /**
         * Method that gets fired before the toggle state change.
         * If this fails, return false will cancel the transition and the state will remain unchanged

         * @param {Object} transition the StateMachine transition object
         * @param {Object} video video options
         * @returns {Object} this.data which contains {muted, self}
         */
        onBeforeToggle(transition, video) {
          if (transition.from !== transition.to) {
            return doToggle(transition, video, meeting)
              .then((locus) => {
                this.setData(video);
                meeting.locusInfo.onFullLocus(locus);

                return Promise.resolve(this.data);
              });
          }

          return Promise.resolve(this.data);
        },

        /**
         * @returns {Object} this.data which contains {muted, self}
         */
        onAfterToggle() {
          return Promise.resolve(this.data);
        },
        plugins: [new StateMachineHistory({max: 5})]
      }
    });
  }
};

export default VideoStateMachine;
