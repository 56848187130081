import {CONTENT} from '../constants';

const MediaSharesUtils = {};

/**
 * parses the relevant values for mediaShares: contentId, disposition
 * @param {Object} mediaShares
 * @returns {undefined}
 */
MediaSharesUtils.parse = (mediaShares) => {
  if (mediaShares) {
    return {
      contentId: MediaSharesUtils.getContentId(mediaShares),
      disposition: MediaSharesUtils.getDisposition(mediaShares)
    };
  }

  return null;
};

/**
 * get the previous and current mediaShares values parsed, as well as the boolean updates
 * @param {Object} oldShare
 * @param {Object} newShare
 * @returns {Object}
 * previous: {Object} old share, current: {Object} new share,
 */
MediaSharesUtils.getMediaShares = (oldShare, newShare) => {
  const previous = oldShare && MediaSharesUtils.parse(oldShare);
  const current = newShare && MediaSharesUtils.parse(newShare);

  return {
    previous,
    current
  };
};

/**
 * get the floor disposition (released, granted)
 * @param {Object} mediaShares
 * @returns {Boolean} disposition
 */
MediaSharesUtils.getDisposition = (mediaShares) => {
  const contentFloor = MediaSharesUtils.extractContentFloor(mediaShares);

  return contentFloor ? contentFloor.disposition : null;
};

/**
 * extract the content property from media shares
 * @param {Object} mediaShares
 * @returns {Object}
 */
MediaSharesUtils.extractContent = (mediaShares) => {
  if (!mediaShares || !mediaShares.length) {
    return null;
  }

  return mediaShares.find((share) => share.name === CONTENT) || null;
};

/**
 * extract the floor property from content object
 * @param {Object} content
 * @returns {Object}
 */
MediaSharesUtils.extractFloor = (content) => {
  if (!content) {
    return null;
  }

  return content.floor;
};

/**
 * extract the content's floor from media shares
 * @param {Object} mediaShares
 * @returns {Object}
 */
MediaSharesUtils.extractContentFloor = (mediaShares) => {
  const content = MediaSharesUtils.extractContent(mediaShares);

  return MediaSharesUtils.extractFloor(content);
};

/**
 * get who is sharing from media shares
 * @param {Object} mediaShares
 * @returns {Object}
 */
MediaSharesUtils.getContentId = (mediaShares) => {
  const contentFloor = MediaSharesUtils.extractContentFloor(mediaShares);

  if (!contentFloor || !contentFloor.beneficiary) {
    return null;
  }

  return contentFloor.beneficiary.id;
};

export default MediaSharesUtils;
