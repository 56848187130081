import StateMachine from 'javascript-state-machine';
import StateMachineHistory from 'javascript-state-machine/lib/history';

import LoggerProxy from '../common/logs/logger-proxy';
import Media from '../media';
import MeetingUtil from '../meeting/util';
import {MEETING_AUDIO_STATE_MACHINE} from '../constants';

const handleTransition = (audio) => {
  if (audio.mute && audio.self) {
    return MEETING_AUDIO_STATE_MACHINE.STATES.MUTE_SELF;
  }
  if (!audio.mute && audio.self) {
    return MEETING_AUDIO_STATE_MACHINE.STATES.UNMUTE_SELF;
  }

  return null;
};

const doToggle = (transition, audio, meeting) => {
  Media.setLocalTrack(!audio.mute, meeting.mediaProperties.audioTrack);
  const meetingVideo = meeting.video;
  const videoMuted = meetingVideo ? meetingVideo.muted : true;

  return MeetingUtil.remoteUpdateAudioVideo(audio.mute, videoMuted, meeting)
    .then((locus) => {
      LoggerProxy.logger.log(
        `Meeting:audio#doToggle --> AudioStateMachine->onAfterToggle#${transition.event} fired! State changed from '${transition.from}' to '${
          transition.to
        }' with transition '${transition.transition}''.`
      );

      return locus;
    })
    .catch((remoteUpdateError) => {
      LoggerProxy.logger.log(
        `Meeting:audio#doToggle --> AudioStateMachine->onBeforeToggle#${transition.event} fired! State failed to change with transition '${
          transition.transition
        }''. After local audio toggle failed, resetting remote also failed, meeting audio in bad state with error: ${remoteUpdateError}.`
      );

      return Promise.reject(remoteUpdateError);
    });
};

const AudioStateMachine = {
  /**
   *
   * @param {Object} mediaDirection An object that contains whether we send audio/video/screen streams
   * @param {Meeting} meeting the meeting instance we are using for this state machine
   * @returns {StateMachine} returns a StateMachine instance
   */
  create(mediaDirection, meeting) {
    if (!mediaDirection.sendAudio) {
      return null;
    }

    return new StateMachine({
      transitions: [
        {
          name: MEETING_AUDIO_STATE_MACHINE.TRANSITIONS.TOGGLE,
          from: '*',
          /**
           *
           * @param {Object} audio The audio options
           * @param {Boolean} audio.mute Whether to mute the meeting or not
           * @param {Boolean} audio.self Whether the was muted by the end user
           * @param {Boolean} audio.remote Whether the meeting was muted by the server
           * @returns {String} returns a new value to set the state to
           */
          to(audio) {
            return handleTransition(audio) || this.state;
          }
        }
      ],
      data: {
        muted: false,
        self: true
      },
      methods: {
        /**
         * Convenience method to return whether the call is muted or not
         * @returns {Boolen} whether the audio is muted or not
         */
        isMuted() {
          return this.muted;
        },

        /**
         * Convenience method to expose this.self
         * @returns {Boolen} this.self
         */
        isSelf() {
          return this.self;
        },

        /**
         *
         * @param {Object} audio the audio state to change
         * @returns {null}
         */
        setData(audio) {
          this.muted = audio.mute;
          this.self = audio.self;
        },

        /**
         * Method that gets fired before the toggle state change.
         * If this fails, return false will cancel the transition and the state will remain unchanged
         * @param {Object} transition the StateMachine transition object
         * @param {Object} audio Audio options
         * @returns {Object} this.data which contains {muted, self}
         */
        onBeforeToggle(transition, audio) {
          if (transition.from !== transition.to) {
            return doToggle(transition, audio, meeting)
              .then((locus) => {
                this.setData(audio);
                meeting.locusInfo.onFullLocus(locus);

                return Promise.resolve(this.data);
              });
          }

          return Promise.resolve(this.data);
        },

        /**
         * @returns {Object} this.data which contains {muted, self}
         */
        onAfterToggle() {
          return Promise.resolve(this.data);
        },
        plugins: [new StateMachineHistory({max: 5})]
      }
    });
  }
};

export default AudioStateMachine;
